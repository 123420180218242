.rich-text table {
  outline: 1px solid theme('colors.tan.DEFAULT');
  width: 100%;
  outline-offset: -1px;
  position: relative;
}

.rich-text table::before {
  top: 0;
  left: -12px;
  right: -12px;
  content: '';
  position: absolute;
  display: block;
  height: 21px;
  background-color: theme('colors.tan.DEFAULT');
}

.rich-text table::after {
  content: '';
  height: 24px;
  display: block;
  width: 1px;
}

.rich-text th {
  padding: 48px 12px 12px 0;
  text-align: start;
  font-weight: 500;
}

.rich-text td,
.rich-text th {
  margin-left: unset !important;
  border-bottom: 1px solid theme('colors.tan.100');
}

.rich-text td {
  padding: 24px 12px 24px 0;
}

.rich-text td:first-child,
.rich-text th:first-child {
  border-left: 24px solid transparent;
}
.rich-text td:last-child,
.rich-text th:last-child {
  border-right: 24px solid transparent;
}

@media only screen and (max-width: 600px) {
  .rich-text td,
  .rich-text th {
    padding: 24px 1rem;
  }

  .rich-text table {
    font-size: theme('fontSize.xs');
  }
}
.nav-cats ul > a {
  height: 120px;
}
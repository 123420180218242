@tailwind base;
@tailwind components;
@tailwind utilities;

@import './css/table.css';
@import './css/university.css';

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-sans), sans-serif;
  color: #424242;
  font-variation-settings: 'opsz' 40;
}

h1,
h2,
h3,
h4,
h5,
h6,
.title-font {
  font-family: var(--font-serif), serif;
  color: #212121;
}

.body-font {
  font-family: var(--font-sans), sans-serif;
}

b,
strong {
  font-weight: 600;
}

/** the layout tab needs to have all it's children have no <Container> padding to match
designs, this will target the .container-padding class in the Container.tsx component
and remove its padding.  This class (tab-no-padding) is added to the body of the layout-tabs.tsx
component */
.tab-no-padding .container-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.sr-hidden-until-focus {
  width: 0px;
  height: 0px;
  opacity: 0;
  overflow: hidden;
}

.sr-hidden-until-focus:focus {
  width: unset;
  height: unset;
  opacity: 1;
}

/** overriding the indicators for the React Responsive Carousel */
.base-slider .control-dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  gap: 1rem;
}

@media only screen and (max-width: 900px) {
  .base-slider .carousel .control-dots {
    bottom: 1rem;
    gap: 1.25rem;
  }

  .base-slider.xl .carousel {
    padding-bottom: 2rem;
  }
}
.base-slider .carousel-status {
  display: none;
}

/** overriding the one trust button styling **/
.ot-sdk-show-settings {
  color: theme('colors.white') !important;
  border: unset !important;
  height: unset !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  padding: unset !important;
  font-size: unset !important;
  line-height: unset !important;
}
.ot-sdk-show-settings:focus,
.ot-sdk-show-settings:hover {
  background-color: unset !important;
}

.rich-text a {
  text-decoration: underline;
  font-weight: 600;
}

.rich-text ul {
  list-style: disc;
  padding: revert;
}

.rich-text ul ul {
  list-style: circle;
}

.rich-text ul ul ul {
  list-style: square;
}

.rich-text ol {
  list-style: decimal;
  padding: revert;
}

.rich-text ol ol {
  list-style: upper-latin;
}

.rich-text ol ol ol {
  list-style: lower-roman;
}

.rich-text ol ol ol ol {
  list-style: lower-latin;
}

.rich-text hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: theme('colors.neutral.400');
}

.base-slider .control-dots {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

/** Mobile browsers (on real phones) view the url bar as part of the view height. 
So if you want something to be 100vh, it will have an undesirable scrollbar on mobile
devices. Add this class to it to ensure on mobile the max height does not include the
url bar. NOTE: DOES NOT WORK WITH POSITION ABSOLUTE, use position: FIXED if needed that way */
.mobile-100vh-clamp {
  max-height: -webkit-fill-available;
}

:root {
  /** University-Setter.tsx will update this variable directly when the univesrity changes */
  --university-color: currentcolor;
}

/** Overriding Tailwind colors here to use the university color */
.university-color .text-primary {
  color: var(--font-sans) !important;
}
.university-color .text-primary-500 {
  color: var(--font-sans) !important;
}

.university-color .text-primary-300 {
  color: var(--font-sans) !important;
}

.university-color .bg-primary {
  background-color: var(--font-sans) !important;
}

/** Overriding University Font */
.university-font * {
  font-family: var(--font-sans);
}
